import Typography from "typography"

const typography = new Typography({
  title: 'ionutt.com',
  baseFontSize: '16px', // default
  scaleRatio: 2,
  googleFonts: [
    {
      name: 'Lato',
      styles: ['900']
    },
    {
      name: 'Open Sans',
      styles: ['300', '600']
    }
  ],
  headerFontFamily: ['Lato'],
  bodyFontFamily: ['Open Sans']
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
